
import React from 'react';
import { Button } from 'react-md';
import { Link as ReactRouterLink } from 'react-router-dom';

import { DASHBOARD_TAX_RETURN } from '@utilities/constants/images';
import { hasPendingTaxReturnStatus } from '@utilities/services/taxReturnService';
import { DOCUSIGN_EMBEDDED_SIGNING } from '@utilities/constants/navigation';

import './style.scss';

function TaxReturnBanner(props) {
    const { taxReturnStatus, isAdmin, isPractitioner } = props;
    const isEmbeddingUrlFeatureEnabled = process.env.REACT_APP_FF_EMBEDDEDSIGNINGURL_FEATURE110977 === true
        || process.env.REACT_APP_FF_EMBEDDEDSIGNINGURL_FEATURE110977 === "true";

    const showTaxReturnBanner = isEmbeddingUrlFeatureEnabled && hasPendingTaxReturnStatus(taxReturnStatus) && !isAdmin && !isPractitioner;

    console.log(`process.env.REACT_APP_FF_EMBEDDEDSIGNINGURL_FEATURE110977: ${process.env.REACT_APP_FF_EMBEDDEDSIGNINGURL_FEATURE110977}`);
    console.log(`showTaxReturnBanner ${showTaxReturnBanner}`);

    if (!showTaxReturnBanner)
        return <></>;

    return (
        <div className={'tax-return-banner'}>
            <div className={'tax-return-icon-text'}>
                <img alt={'TAX RETURN'} src={DASHBOARD_TAX_RETURN} />
                <div className={'tax-return-base-text'}>
                    <div className={'header-text'}>Your tax documents are ready to sign.</div>
                    <div>Since your last visit to the 1040, a few documents could use your final signature. Thank you.</div>
                </div>
            </div>
            <ReactRouterLink to={DOCUSIGN_EMBEDDED_SIGNING} onClick={() => window.scrollTo(0, 0)}>
                <Button
                    themeType="contained"
                    className={'entryNextButton uploadButton sign-tax-return-btn'}
                >
                    Sign your tax return
                </Button>
            </ReactRouterLink>
        </div>
    );
}

export default TaxReturnBanner;