import _ from 'lodash';

import api from '@utilities/claApi';
import { dashboard as defaultDashboard } from '@utilities/constants/dashboard';
import { loadOrganizerDetails } from '@utilities/helpers/loadOrganizerDetails';
import { setRequiredForms } from '@utilities/helpers/setRequiredForms';

const setSelectedClientDetails = async (
  organizer,
  ACTION,
  dispatch,
) => {
  const { id, client, status, year, priorYearReturnId, taxpayer, spouse } = organizer;
  const taxPayerName = taxpayer?.displayName;
  const taxpayerSpouseName = spouse?.displayName;

  if (!taxPayerName) throw new Error('Taxpayer data not found');
  dispatch(ACTION.setTaxpayerName(taxPayerName));
  dispatch(ACTION.setpriorYearReturnId(priorYearReturnId));
  dispatch(ACTION.setTaxPayerSpouseName(taxpayerSpouseName));
  dispatch(ACTION.setOrganizerId(id));
  dispatch(ACTION.setSelectedOrganizer(organizer));
  dispatch(ACTION.setActiveReturn({
    displayName: client?.name,
    clientNumber: client?.number ?? '',
    formStatus: status,
    currentYear: year
  }));

  const dashboardResponse = await api.get(`/organizers/${id}/dashboard`);
  const priorYearResponse = await api.get(`/organizers/${id}/prior`);

  const dashboard = dashboardResponse?.data?.dashboard || null;
  const priorYearData = priorYearResponse?.data?.data?.taxData?.priorYear || null;

  const dashCopy = setRequiredForms(priorYearData || [], _.cloneDeep(dashboard || defaultDashboard));
  const latestDashboard = _.cloneDeep(dashCopy);

  dispatch(ACTION.setDashboard(latestDashboard));
  dispatch(ACTION.setDashboardId(dashboardResponse?.data?.id));
};

const setTaxpayerDetails = async ({
  isEntry,
  currentTaxPayer,
  activeReturn,
  organizer,
  ACTION,
  dispatch,
  history,
  updateUploadList,
  setEntityFormState,
  clearFormState
}) => {
  const { id, client, isTermsAgreed, entryExperience, taxpayer, spouse } = organizer;
  const taxPayerName = taxpayer?.displayName;
  const taxpayerSpouseName = spouse?.displayName;

  if (!isEntry) {
    // return if same taxpayer is selected
    if (client?.number === activeReturn?.clientNumber) return;

    // check if agreement has been signed
    if (!entryExperience?.completedStep) {
      // Show the progress modal immediately
      dispatch(ACTION.setProgressText(`Loading Client Data for\n${taxPayerName}${taxpayerSpouseName ? ` \nand ${taxpayerSpouseName}` : ''}`));
      dispatch(ACTION.setProgressVisible(true));

      clearFormState();

      // Set a 2-second timeout before showing the entry modal
      setTimeout(async () => {
        // Hide the progress modal
        dispatch(ACTION.setProgressVisible(false));

        // Open the entry modal and proceed with other actions after 2 seconds
        dispatch(ACTION.setEntryModalOpen(true));
        dispatch(ACTION.setCompletedEntryStep(0));
        await setSelectedClientDetails(
          organizer,
          ACTION,
          dispatch,
        );
        await loadOrganizerDetails(id, dispatch, ACTION, api, updateUploadList, setEntityFormState, client?.number);

        if (isTermsAgreed) {
          history.push({
            pathname: '/',
            state: { gettingStarted: true }
          });
        } else {
          history.push({
            pathname: '/',
            state: { agreementAcceptance: true }
          });
        }
      }, 3000); // Delay for 2 seconds
    } else {
      dispatch(ACTION.setProgressText(`Loading Client Data for\n${taxPayerName}${taxpayerSpouseName ? ` \nand ${taxpayerSpouseName}` : ''}`));
      dispatch(ACTION.setProgressVisible(true));

      clearFormState();

      setTimeout(async () => {
        // Hide the progress modal
        dispatch(ACTION.setProgressVisible(false));
        await setSelectedClientDetails(
          organizer,
          ACTION,
          dispatch,
        );
        await loadOrganizerDetails(id, dispatch, ACTION, api, updateUploadList, setEntityFormState, client?.number);
      }, 3000); // Delay for 3 seconds
    }
  }
  // get the organizer dashboard
  else {
    try {
      await setSelectedClientDetails(
        organizer,
        ACTION,
        dispatch,
      );
    } catch (error) {
      console.error('Error getting the organizer data', error.message);
      dispatch(ACTION.setShowCustomDialog(true));
      dispatch(ACTION.setCustomDialogTitle(error.message || 'Error'));
    } finally {
      dispatch(ACTION.setProgressVisible(false));
    };
  }
};

export {
  setSelectedClientDetails,
  setTaxpayerDetails
};